<template>
  <section class="m-merchant m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/merchant/icon-fanhui@2x.png" alt="back">
      <p>优选商家</p>
      <span></span>
    </div>
    <div class="header-bg"></div>

    <van-list v-model="loading"  :finished="finished" finished-text="无更多数据" class="list m-padding">
      <div class="item" v-for="(it,index) in shop" :key="index">
        <div class="title">
          <p>{{it.title}}</p>
          <p class="van-multi-ellipsis--l2">{{it.shop_content}}</p>
          <router-link :to="`/zh/merchant/index/${it.id}`">
            <span>进店</span>
            <img src="@/assets/merchant/right-arrow@2x.png" alt="">
          </router-link>
        </div>
        <div class="imgs-wrap m-scroll">
          <router-link v-for="(img, index) in it.shops_goods" :key="index" :to="`/zh/goods/detail/${img.id}`">
            <img :src="img.image_url" alt="goods">
          </router-link>
        </div>
      </div>
    </van-list>

  </section>
</template>

<script>
import { shops } from '@/api/zh/index.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'MerchantList',
  components:{  },
  data(){
    return {
      shop: [],
      page: 1,
      loading: false,
      finished: true,
      islock: false,
    }
  },
  created(){
    this.getData()
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.islock = true
          this.page++
          this.getData()
        }
      }
    },
    getData() {
      let params = { is_page: 1, page: this.page, per_page: 20 }
      shops(params).then(res => {
        this.islock = false
        if(res) {
          this.shop = this.shop.concat(res.data.data)
        } else {
          this.finished = true
          this.islock = true
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 返回上一页
    handleBack(){
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="less" scoped>
@import './list.less';
</style>